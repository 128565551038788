import React from 'react';
import Header from "../../components/landing-page-new/header/Header";
import LandingPageFooter from "../../components/landing-page/landing-footer/LandingPageFooter";
import OpenShipAIChat from "../../components/open-ai-chat/OpenShipAIChat";
import styles from "./AIPage.module.scss";
import Description from "../../components/landing-page-new/description/Description";
import {Fade} from "react-awesome-reveal";

const AiPage = () => {
    return (
        <>
            <Header/>
            <Fade>
                <div className={styles.aiPage}>
                    <OpenShipAIChat/>
                </div>
            </Fade>
            <Fade>
                <Description
                    title="AI Transforming the World"
                    description="Artificial Intelligence (AI) is revolutionizing industries globally, driving unprecedented efficiency, accuracy, and innovation. From healthcare to finance, AI is reshaping how we live and work, fostering growth and creating new opportunities. OpenShipAI is leveraging this transformative power to redefine logistics, making it smarter, faster, and more transparent. By integrating advanced machine learning algorithms, our AI solutions optimize route planning, automate warehousing operations, and predict demand fluctuations with precision. This not only enhances productivity but also significantly reduces operational costs, contributing to a sustainable and scalable logistics ecosystem."
                    subDescriptions={[
                        {
                            title: "AI in Logistics: Enhancing Efficiency and Reliability",
                            content: "In the logistics industry, where time and accuracy are critical, AI brings a level of precision and efficiency that was previously unattainable. AI-driven analytics provide deep insights into supply chain dynamics, enabling businesses to anticipate disruptions and adapt proactively. Our AI technology continuously learns and improves, ensuring that our logistics solutions remain cutting-edge and highly effective. By utilizing predictive analytics, we can forecast demand, manage inventory more efficiently, and optimize delivery routes to reduce delays and costs. This level of optimization leads to improved service quality and customer satisfaction, making logistics operations more reliable and efficient."
                        },
                        {
                            title: "Real-Time Tracking and Automation",
                            content: "OpenShipAI’s AI-driven platform offers real-time tracking and automation, providing our clients with up-to-the-minute information about their shipments. This transparency allows businesses to monitor their goods' progress, manage exceptions, and make informed decisions quickly. Automation of routine tasks such as documentation, invoicing, and communication reduces the administrative burden on businesses, freeing up valuable resources to focus on core activities. Our AI technology also enhances security by monitoring shipments for potential risks and anomalies, ensuring that goods are transported safely and securely."
                        },
                        {
                            title: "Sustainability and Scalability through AI",
                            content: "Our commitment to sustainability is reinforced by our use of AI to minimize environmental impact. By optimizing routes and reducing fuel consumption, we help decrease the carbon footprint associated with logistics. AI also enables more efficient use of resources, reducing waste and promoting sustainable practices across the supply chain. Scalability is another significant advantage of AI; as your business grows, our AI solutions can adapt to increasing volumes and complexity without compromising efficiency. This scalability ensures that our logistics services can support your business's growth and evolving needs."
                        },
                        {
                            title: "Driving Innovation in Logistics with AI",
                            content: "At OpenShipAI, we are dedicated to driving innovation in logistics through the power of AI. Our continuous investment in research and development ensures that we stay ahead of industry trends and technological advancements. We collaborate with leading experts and institutions to refine our algorithms and develop new solutions that address emerging challenges in the logistics sector. Our innovative spirit is reflected in our commitment to providing our clients with the most advanced and effective logistics solutions available."
                        },
                        {
                            title: "Shaping the Future of Logistics",
                            content: "As we continue to innovate, OpenShipAI is committed to driving the future of logistics with AI at its core. Our vision is to create a more efficient, transparent, and sustainable logistics industry where businesses can thrive, and customers receive unparalleled service. By harnessing the power of AI, we are setting new standards for logistics operations, ensuring that our clients can navigate the complexities of global supply chains with confidence and ease. Join us on this journey and experience the transformative power of AI in logistics."
                        }
                    ]}
                />

            </Fade>
            <Fade>
                <LandingPageFooter/>
            </Fade>
        </>
    );
};

export default AiPage;
export const BACKEND_URL = 'http://localhost:8080';
export const FRONTEND_URL = 'http://localhost:3000';
export const SOCKET_URL = 'http://localhost:8083';
export const ASSISTANT_URL = 'http://localhost:7777';
export const IMAGE_URL = 'https://0697a061088f392c1c68a5e8320b3f6b.r2.cloudflarestorage.com/my-images';


/*
http://localhost:8080
http://localhost:8083
http://localhost:3000
http://localhost:7777
https://openshipai-assistant-server.onrender.com
https://openshipai-backend.onrender.com
https://socket-chat-server-xly7.onrender.com
*/

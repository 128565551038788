import React from 'react';
import Header from "../../components/landing-page-new/header/Header";
import LandingPageFooter from "../../components/landing-page/landing-footer/LandingPageFooter";
import Description from "../../components/landing-page-new/description/Description";


const PartnersPage = () => {
    return (
        <>
            <Header/>
            <Description
                title="Partners"
                description="At OpenShipAI, we offer a streamlined dispatch service for carriers at a competitive rate of just 5.5%. This service is designed to maximize your efficiency and profitability by leveraging our advanced AI technology and extensive network. With our dispatch service, you benefit from optimized load matching, reduced deadhead miles, and increased load acceptance rates. Our intuitive platform simplifies the process of finding and securing loads, allowing you to focus on what you do best—delivering exceptional service."
                subDescriptions={[
                    {
                        title: "Why OpenShipAI is the Best Solution for Carriers",
                        content: "Advanced AI Technology: Our AI-driven platform uses machine learning algorithms to match carriers with the most suitable loads, ensuring high efficiency and maximizing earnings. By analyzing historical data and real-time market conditions, we provide you with the best opportunities available. Comprehensive Support: We offer 24/7 support to assist you with any issues or questions. Our dedicated team is always available to help you navigate our platform, manage shipments, and optimize your operations. Real-Time Tracking and Transparency: Our platform provides real-time tracking and status updates, giving you full visibility of your shipments. This transparency helps build trust with shippers and enhances your reputation as a reliable carrier. Competitive Rates and Flexible Payments: Our competitive dispatch rate of 5.5% ensures you get the best value for your money. We also offer flexible payment options, including quick pay and factoring services, to help you maintain a steady cash flow."
                    },
                    {
                        title: "Legal and Insurance Safety Requirements",
                        content: "OpenShipAI is committed to maintaining the highest standards of safety and compliance. All our carriers must meet strict legal and insurance requirements to join our network: FMCSA Compliance: Every carrier must comply with the Federal Motor Carrier Safety Administration (FMCSA) regulations. This includes maintaining up-to-date operating authority, safety ratings, and compliance with Hours of Service (HOS) regulations. Comprehensive Insurance Coverage: Carriers must have active insurance policies that meet or exceed industry standards. This includes liability insurance, cargo insurance, and any additional coverage required for specific types of freight. Our platform verifies insurance details to ensure every load is protected. Vetted and Verified Carriers: We conduct thorough background checks and safety audits on all carriers in our network. This vetting process includes reviewing safety records, insurance policies, and compliance with regulatory requirements. Only carriers that meet our stringent criteria are allowed to operate under OpenShipAI."
                    },
                    {
                        title: "Enhancing Your Operations with OpenShipAI",
                        content: "Partnering with OpenShipAI means you benefit from our industry-leading technology, comprehensive support, and commitment to safety and compliance. Our platform is designed to enhance your operations, reduce administrative burdens, and increase profitability. Here’s how we do it: Optimized Load Matching: Our AI algorithms analyze multiple data points to match you with the best loads, reducing empty miles and increasing your load acceptance rate. Efficient Route Planning: With our route optimization tools, you can plan the most efficient paths, saving time and fuel costs. This not only improves your bottom line but also contributes to a more sustainable operation. Seamless Communication: Our platform facilitates seamless communication between carriers and shippers, ensuring that all parties are informed and updated throughout the shipping process. Access to a Large Network: As part of the OpenShipAI network, you gain access to a vast pool of shippers and freight opportunities. This extensive network ensures that you always have access to the best loads available."
                    },
                    {
                        title: "Join OpenShipAI Today",
                        content: "If you’re a carrier looking for a reliable, efficient, and profitable solution, look no further than OpenShipAI. Our 5.5% dispatch service, advanced technology, and unwavering commitment to safety and compliance make us the best choice for your logistics needs. Join us today and experience the benefits of partnering with an industry leader."
                    }
                ]}
            />
            <LandingPageFooter/>
        </>
    );
};

export default PartnersPage;